import React, { useState, useEffect } from 'react';
import Slider from "react-slick";
import helper from '../helper';

const { getServicesByLocationId } = helper;

const isMobileView = () => {
  return window?.screen?.width < '770';
}

function Services ({ locationId }) {
    const [centerIndex, setCenterIndex] = useState(0);
    const updateIndex = (i) => {
        setCenterIndex(i);
    }

    return(
        <div className="becky-app__services p-5">
            <div className="becky-sub-title">
                What’s interesting about Becky’s ?
            </div>
            <div className="becky-app__services__subtext w-[100%]">
                Becky's Bow Wow House stands out for its experienced, trustworthy, and quality care, with a dedicated team of highly experienced animal lovers,
                a strong emphasis on trust and pet well-being, and a commitment to providing top-notch services for your dog.
            </div>
            <div className="becky-app__services__list">
                <SimpleSlider locationId={locationId} setCenterIndex={updateIndex} />
            </div>
            <div className="becky-app__services__detail">
                <ServicesDetails locationId={locationId} serviceIndex={centerIndex}/>
            </div>

        </div>
    )
}

function SimpleSlider({ setCenterIndex, locationId }) {
  const services = getServicesByLocationId(locationId);
  const [sliderConfig, setSliderConfig] = useState({
    autoplay: false,
    autoplaySpeed: 3000,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: isMobileView() ? 1 : 3,
    centerMode: true,
    beforeChange: function (oldIndex, newIndex){
      setCenterIndex(newIndex);
    },
  });

  useEffect(() => {
    if (isMobileView()) {
      setSliderConfig((prevState) => ({
        ...prevState,
        arrows: false,
        slidesToShow: 1,
      }));
    } else {
      setSliderConfig((prevState) => ({
        ...prevState,
        arrows: true,
        slidesToShow: 3,
      }));
    }
  }, []);

    return (
        <div>
                <Slider {...sliderConfig} >
                    {
                        services.map((service, index) =>
                            <img key={index} src={`/assets/${service.image}.png`} alt={`${service.image}`}/>
                        )
                    }
                </Slider>
        </div>
    );
}

function ServicesDetails ({ serviceIndex, locationId }) {
  const services = getServicesByLocationId(locationId);
  return(
        <>
            <div className="becky-app__services__detail__header becky-text-sub-heading w-1/2 p-4 text-center">
                { services[serviceIndex]?.title }
                <div className="becky-border-bottom"></div>
            </div>
            <div className="becky-app__services__detail__description becky-text-small-center text-bbw-gray w-1/2">
                { services[serviceIndex]?.description }
            </div>
        </>
    );
}


export default Services;