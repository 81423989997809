import React, {useState} from 'react';
import PhotoAlbum from 'react-photo-album';
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

// import optional lightbox plugins
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/thumbnails.css";

const photos = [
    {
        src: '/assets/gallery1.jpeg',
        width: 3,
        height: 3,
    },
    {
        src: '/assets/gallery3.webp',
        width: 4,
        height: 3,
    },
    {
        src: '/assets/gallery2.webp',
        width: 4,
        height: 4,
    },
    {
        src: '/assets/gallery4.webp',
        width: 2,
        height: 2,
    },
    {
        src: '/assets/gallery6.webp',
        width: 3,
        height: 2,
    },
    {
        src: '/assets/gallery5.webp',
        width: 3,
        height: 2,
    },
    {
        src: '/assets/gallery7.webp',
        width: 2,
        height: 2,
    },
];

const photosLightBox = [...photos.map(item => ({src: item.src}))]

function Gallery () {
    const [index, setIndex] = useState(-1);
    return(
        <header className="becky-app__gallery">
            <div className="becky-app__gallery__header becky-text-heading text-center">Becky's Gallery</div>
            <div className="becky-app__gallery__photos">
                <PhotoAlbum
                    layout="rows"
                    photos={photos}
                    spacing={2}
                    onClick={({ index }) => setIndex(index)}
                />
                <Lightbox
                    slides={photosLightBox}
                    open={photosLightBox.length && index >= 0}
                    index={index}
                    close={() => setIndex(-1)}
                    // enable optional lightbox plugins
                    plugins={[Fullscreen, Slideshow, Thumbnails, Zoom]}
                />
            </div>
        </header>
    )
}

export default Gallery;