import React from "react";
import helper from '../helper';

const { getLocationsList } = helper;
const locationList = getLocationsList();

function LocationsWidget({selectedLocationId, setSelectedLocationId}) {
    const handleLocationChange = (event) => {
        setSelectedLocationId(event.target.value);
    };
    return(
        <div className="becky-app__location flex justify-center sm:justify-end items-center">
            <label htmlFor="location">
                <img src="/assets/location.png" className="h-[24px]"/>
            </label>
            <select id="location" name="location" value={selectedLocationId} onChange={handleLocationChange}>
                <option value="">Select...</option>
                {locationList.map((location) => (
                    <option value={location.value}> {location.label} </option>
                ))}
            </select>
        </div>
    );
}

export default LocationsWidget;