/* Location IDs  */
const LOCATION_ID = {
  BLR_SRJ: 'blr01',
  BLR_BSK: 'blr02',
  GGN: 'ggn',
}

const COORDINATES = {
  [LOCATION_ID.BLR_BSK]: { latitude: 12.9207473414966, longitude: 77.56524991474735 },
  [LOCATION_ID.BLR_SRJ]: { latitude: 12.89390432861363, longitude: 77.67886351110283 },
  [LOCATION_ID.GGN]: { latitude: 28.412118333391078, longitude: 77.07329398049124 },
}


const LOCATIONS_NAME = {
  [LOCATION_ID.BLR_SRJ]: 'Bangalore - Sarjapur',
  [LOCATION_ID.BLR_BSK]: 'Bangalore - Banashankari',
  [LOCATION_ID.GGN]: 'Gurgaon'
}

const getLocationsList = () => {
  return Object.keys(LOCATIONS_NAME).map((location) => {
    return {
      label: LOCATIONS_NAME[location],
      value: location,
    }
  })
}

const getAddress = (locationId) => {
  switch (locationId) {
    case LOCATION_ID.BLR_SRJ:
      return '29/30, 2nd Cross, 5th main, Amrita Nagar, Hosa road - 560035';
    case LOCATION_ID.BLR_BSK:
      return '1867, 31st Cross, 11th main, Banashankari 2nd stage - 560070';
    case LOCATION_ID.GGN:
      return '17/1, 410, Golf course extension, sector 63, Gurugram - 122101';
    default:
      return '29/30, 2nd Cross, 5th main, Amrita Nagar, Hosa road - 560035';
  }
}

const basicServices = [
  {
    title: 'Dog Boarding',
    description: 'At Becky’s House, we offer spacious and comfortable accommodations for dogs of all sizes. ' +
      'Our facilities are designed to create a homely atmosphere for your furry friends while you’re away. ' +
      'Our experienced staff genuinely cares about your dogs’ well-being, providing the love and attention each dog deserves.',
    image: 'boarding'
  },
  {
    title: 'Dog Grooming',
    description: 'At Becky’s House, we collaborate with professionally trained groomers to deliver top-quality service. ' +
      'Our professional grooming services are designed to ensure your dog looks and feels their best. ' +
      'Regular grooming not only enhances your dog’s appearance but also contributes to their overall health.',
    image: 'grooming'
  },
  {
    title: 'Doggy Splash Zone',
    description: 'Dive into a world of aquatic joy with our Doggy Splash Zone! Our custom-designed swimming pool provides a refreshing and safe environment for dogs to paddle and play. Supervised by trained professionals, your furry friend can make a splash and enjoy the ultimate water experience tailored just for them.',
    image: 'splash'
  },
  {
    title: 'Playful Paws Day Care',
    description: 'At Playful Paws Day Care, we understand that a happy dog is an active dog. Our services offer a perfect blend of social interaction, mental stimulation, and physical exercise. Our dedicated team ensures a fun and secure environment, where your pup can socialize, play games, and receive personalized attention throughout the day. It\'s a haven for joyous tails and wiggling noses!',
    image: 'daycare'
  },
];

const blr01Services = [
  ...basicServices,
];

const blr02Services = [
  ...blr01Services,
  {
    title: 'Paw-ty Central',
    description: 'Celebrate your pup\'s special day at Paw-ty Central! Our birthday parties are a tail-wagging extravaganza filled with joy, treats, and dog-friendly festivities. Let us make your dog\'s birthday memorable with games, toys, and a paw-some celebration surrounded by furry pals. It\'s the perfect way to show your dog some extra love on their big day!',
    image: 'pawty'
  },
];

const ggnServices = [
  ...blr02Services,
  {
  title: 'Bark Oasis',
    description: 'Discover the ultimate canine playground at Bark Oasis! Our dog park is a haven for off-leash fun, where dogs can roam, run, and play to their heart\'s content. With secure fencing and carefully designed play areas, it\'s the ideal space for dogs to socialize, exercise, and enjoy the great outdoors. Bring your furry friend to Bark Oasis for a tail-wagging good time!',
  image: 'oasis'
  },
]

const getServicesByLocationId = (locationId) => {
    switch (locationId) {
      case LOCATION_ID.BLR_SRJ:
        return blr01Services;
      case LOCATION_ID.BLR_BSK:
        return blr02Services;
      case LOCATION_ID.GGN:
        return ggnServices;
      default:
        return blr01Services;
    }
}

function getInitials(name = '') {
  const nameParts = name.split(' ');

  if (nameParts.length === 1) {
    // Only the first name is provided
    return `${nameParts[0][0]}`;
  } else if (nameParts.length >= 2) {
    // Both first and last names are provided
    return `${nameParts[0][0]}${nameParts[1][0]}`;
  } else {
    // No name is provided
    return '';
  }
}

function generateBackground(name) {
  let hash = 0;
  let i;
  for (i = 0; i < name.length; i += 1) {
    hash = name.charCodeAt(i) + ((hash << 5) - hash);
  }
  let color = '#';
  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  return color;
}

const determineLocationFromCoordinates = (latitude, longitude) => {
  // Coordinates of Bangalore BSK, India
  const bangaloreBskCoords = COORDINATES[LOCATION_ID.BLR_BSK];
  // Coordinates of Bangalore SRJ, India
  const bangaloreSrjCoords = COORDINATES[LOCATION_ID.BLR_SRJ];
  // Coordinates of Gurgaon, India
  const gurgaonCoords = COORDINATES[LOCATION_ID.GGN];

  // Calculate distances
  const distanceToBangaloreBsk = calculateDistance(latitude, longitude, bangaloreBskCoords.latitude, bangaloreBskCoords.longitude);
  const distanceToBangaloreSrj = calculateDistance(latitude, longitude, bangaloreSrjCoords.latitude, bangaloreSrjCoords.longitude);
  const distanceToGurgaon = calculateDistance(latitude, longitude, gurgaonCoords.latitude, gurgaonCoords.longitude);

  // Determine the closest city
  let closestLocId = LOCATION_ID.BLR_BSK;
  if (distanceToGurgaon < distanceToBangaloreSrj) {
    closestLocId = LOCATION_ID.GGN;
  } else if (distanceToBangaloreSrj < distanceToBangaloreBsk) {
    closestLocId = LOCATION_ID.BLR_SRJ;
  }
  return closestLocId;
};

const calculateDistance = (lat1, lon1, lat2, lon2) => {
  if (!(isNaN(lat1) || isNaN(lon1) || isNaN(lat2) || isNaN(lon2))) {
    const R = 6371; // Earth radius in kilometers
    const dLat = toRadians(lat2 - lat1);
    const dLon = toRadians(lon2 - lon1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(toRadians(lat1)) * Math.cos(toRadians(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = R * c;
    return distance;
  }
  return 0;
};

const toRadians = (degrees) => {
  return degrees * (Math.PI / 180);
};



export default {
  getServicesByLocationId, LOCATION_ID, LOCATIONS_NAME, getLocationsList, getAddress, getInitials, generateBackground, determineLocationFromCoordinates
}