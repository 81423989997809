import React from 'react';
import helper from '../helper';
import LocationsWidget from "../widgets/locations";

const { getLocationsList } = helper;
const locationList = getLocationsList();

function Header ({
    setShow, selectedAbout, scrollToGallery, scrollToServices, setScrollToCommunity, selectedLocationId, setSelectedLocationId }) {

    return(
      <>
        <header className="becky-app__header">
          <div className="becky-app__header__logo" onClick={() => setShow(false)}>
            <img className="becky-app__header__logo--large hidden sm:block md:block lg:block" src="/assets/logo.png" alt="logo"/>
              <img className="becky-app__header__logo--small block sm:hidden md:hidden lg:hidden" src="/assets/favicon.png" alt="logo"/>
          </div>
          <div className="becky-app__header__nav">
            <span className={selectedAbout ? 'font-bold' : ''} onClick={() => { setShow(true); setScrollToCommunity(false)}}>About Us</span>
            <span onClick={scrollToGallery}>Gallery</span>
            {/*<span>Contact Us</span>*/}
            <span onClick={scrollToServices}>Services</span>
          </div>
          <div className="flex justify-center items-center">
            <div className="hidden md:block lg:block sm:block">
              <LocationsWidget selectedLocationId={selectedLocationId} setSelectedLocationId={setSelectedLocationId} />
            </div>
            <button className="beck-button-sm font-semibold pr-8 pl-8 h-[40px]" onClick={
              () => window.open('https://doggiedashboard.com/users/49a9896cd7c483d3b233/new_client', '_blank')
            }>Book now</button>
          </div>
          <div className="becky-app__header__community">
            <button onClick={() => { setShow(true); setScrollToCommunity(true)}}>Community</button>
          </div>
        </header>
      </>
    )
}

export default Header;
