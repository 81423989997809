import React from 'react';
function Community () {
  return(
    <div className="becky-app__community">
      <div className="becky-app__community-body">
        <span>are you <br /> missing cool parties!</span>
        <div className="becky-app__community-content">
          <div className="becky-app__community-content-description">
          We're dedicated to crafting daily moments of joy for your furry companions. Join our community and celebrate their happiness with us.
          </div>
          <button className="becky-app__community-content-action" onClick={
              () => window.open('https://instagram.com/beckysbowwowhouse_bsk?igshid=MTh4ZTlueW1jYmE5ZQ==', '_blank')
          }> Join Community </button>
        </div>
      </div>
      <div className="becky-app__community-wallpaper">
        <span>are you <br /> missing cool parties!</span>
      </div>

    </div>
  )
}

export default Community;
