import React from 'react';
function Join () {
  return(
    <div className="becky-app__join-us">
      <div className="becky-app__join__heading">
        Connecting Pets,<br />
        Pawsitively!
      </div>
      <div className="becky-app__join__action">
        <button> Join Positivity </button>
      </div>
      <div className="becky-app__join__subText">
        We have served over <span className="text-bbw-red">25000+</span> satisfied customers to date.
      </div>
    </div>
  )
}

export default Join;