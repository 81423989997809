import React from 'react';
import './About-style.css';

function PotentialCard ({title = '', description = '', iconName = ''}) {
  return(
    <div key={iconName} className="becky-app-p-card">
        <div className="becky-app-p-card__icon">
          <img src={`assets/${iconName}.png`} alt={iconName || 'No image' } height='50px' width='50px' />
        </div>
      <div className="becky-app-p-card__title">
        {title}
      </div>
      <div className="becky-app-p-card__description">
        {description}
      </div>
    </div>
  )
}

export default PotentialCard;
