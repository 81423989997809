import React from 'react';
import PotentialCard from './PotentialCard';

const qualitiesList = [
  {
    title: 'Resembles Home',
    description: 'At Becky\'s House, we create a home away from home for your furry friends. Our dedicated staff ensures a personalized and secure environment, prioritizing their well-being and giving you peace of mind during their stay.',
    iconName: 'home',
    id: 1,
  },
  {
    title: 'No Confinement',
    description: 'We believe in providing dogs with a stay free from confinement, ensuring they have the space and freedom to enjoy their time with us',
    iconName: 'no_confinement',
    id: 2,
  },
  {
    title: 'Well Trained Staff',
    description: 'Our team of trained and compassionate staff is committed to providing top-notch care, ensuring that every pet\'s stay is handled with the utmost professionalism and respect.',
    iconName: 'euthanasia',
    id: 3,
  },
  {
    title: 'Staff-to-pet ratio',
    description: 'We pride ourselves on maintaining an exceptional staff-to-pet ratio to guarantee each pet receives the personalized care and attention they need throughout their stay.',
    iconName: 'ratio',
    id: 4,
  },
  {
    title: 'Mood Management',
    description: 'Our expert mood management techniques ensure that your pets are consistently happy and content, creating a stress-free and enjoyable environment for them.',
    iconName: 'mood',
    id: 5,
  },
  {
    title: 'Precise Grooming',
    description: 'Our precise grooming services are designed to bring out your pet\'s finest appearance, leaving them not only clean and healthy but also looking their absolute best',
    iconName: 'cleaning',
    id: 6,
  },
]

function Qualities () {
  return(
    <div className="becky-app__qualities">
      <div className="becky-app__qualities__heading">
        Unlock the Paw-some Potential with a <br /> Perfect Companion!
      </div>
      <div className="becky-app__qualities__cards">
          {qualitiesList.map(qal => (
            <div key={qal.id} className="becky-app-p-card-container">
            <PotentialCard
              key={qal.id}
              title={qal.title}
              description={qal.description}
              iconName={qal.iconName}
            />
            </div>
          ))}
      </div>
    </div>
  )
}

export default Qualities;
