import React from 'react';
import LetteredAvatar from './LetterAvatar';

const testimonials = [
    {
        name: 'Pulkit Maheshwari',
        address: 'Gurugram, Haryana',
        image: '/assets/profile-image-1.png',
        review: 'They are every pet parent\'s dream! The moment you step inside, you\'ll love the open spaces and safe sections for your pet to roam.' +
            'Our air-conditioned rooms with balconies make your pet feel at home. And the cherry on top? The largest swimming pool for pets you\'ve ever seen!'
    },
    {
        name: 'NS Kashyap Karmakar',
        address: 'Bangalore, Karnataka',
        image: '/assets/profile-image-1.png',
        review: 'My first time boarding my dog at Becky\'s was a fantastic experience. The knowledgeable and trustworthy staff took great care of my Labrador, ' +        
            'and I felt completely at ease during my travel, knowing my lab was likely having a blast. I highly recommend it as a safe haven for dogs in the care of qualified professionals.'
    },
    {
        name: 'Lovekush',
        address: 'HSR Layout, Bangalore',
        image: '/assets/profile-image-1.png',
        review: 'They\'ve cared for my two dogs for six days, offering both daycare and boarding. My dogs always return home happy and tired after a fun day with the Becky\'s team. ' + 
            'The office team is also very easily accessible, and communication has always been quick and open'
    },
    {
        name: 'Suvendu Karmakar',
        address: 'HSR Layout, Bangalore',
        image: '/assets/profile-image-1.png',
        review: 'Best place to visit with your dog. My Bruno had the best time for his life. ' +
            'He made new friends and the owner is super helpful and sweet. Highly recommended.'
    },
    {
        name: 'Malavika AS',
        address: 'HSR Layout, Bangalore',
        image: '/assets/profile-image-1.png',
        review: 'We left our German Shepherd dog for five days here and we are very satisfied with the care and facilities they provided. ' +
            'Will recommend it to every dog parent looking for a trustworthy place to leave their furry friends'        
    },
];

function Testimonials () {
    return(
        <header className="becky-app__testimonials">
            <div className="becky-app__testimonials__header becky-text-heading text-center">What our clients think about us?</div>
            <div className="becky-app__testimonials__cards">{
                testimonials.map((testimonial) =>
                    <TestimonialCard cardData={testimonial} />
                )
            }</div>
        </header>
    )
}

function TestimonialCard ({cardData}) {
    return (
        <div className="becky-app__testimonials__card becky-flex-center-horizontal flex-col becky-card-layout">
            <div className="becky-flex-center-vertical-lg flex mb-1.5">
                {/*<img width={'75px'} src={cardData.image}  alt={cardData.name}/>*/}
                <LetteredAvatar name={cardData.name || ''} />
                <div className="flex justify-center flex-col items-start">
                    <div>{cardData.name}</div>
                    <div className='becky-text-x-small text-bbw-gray'>{cardData.address}</div>
                </div>
            </div>
            <div>
                <div className="becky-flex-center-vertical justify-start">
                    <img className="mb-3" width={'12px'} src="/assets/inverted-comma-start.png" alt="quote"/>
                </div>
                {cardData.review}
                <div className="becky-flex-center-vertical justify-end">
                    <img className="mt-3" width={'12px'} src="/assets/inverted-comma-end.png" alt="quote"/>
                </div>
            </div>
        </div>
    )
}

export default Testimonials;