import React from 'react';
function Hero () {
    return(
        <div className="becky-app__hero">
            <div className="becky-app__hero__text">
                <div className="w-10/12">
                    <div className="becky-main-title mb-8">
                        Is your dog's happiness your top priority?
                    </div>
                    <div className="becky-section-description text-base mb-2 2xl:mb-2.5">
                    At Becky's, our top priority is ensuring your dog's happiness. Every member of our family is a highly experienced animal lover.
                    </div>
                    <button className="becky-button font-semibold mt-8 pr-8 pl-8 hidden" onClick={
                        () => window.open('https://doggiedashboard.com/users/49a9896cd7c483d3b233/new_client', '_blank')
                    }>Book now</button>
                </div>
            </div>
            <div className="becky-app__hero__image">
                <img src="/assets/hero.png" alt="hero"/>
            </div>
            <div className="becky-app__hero__services">
                <span className="becky-app__hero__services__item border-right">Dog Boarding</span>
                <span className="becky-app__hero__services__item border-right">Pet Grooming</span>
                <span className="becky-app__hero__services__item border-right">Swimming</span>
                <span className="becky-app__hero__services__item">Pet Taxi</span>
            </div>
        </div>
    )
}

export default Hero;