import './App.css';
import Header from "./sections/header";
import Hero from "./sections/hero";
import Introduction from "./sections/introduction";
import Banner from "./sections/banner";
import Services from "./sections/services";
import Gallery from "./sections/gallery";
import Testimonials from "./sections/testimonials";
import Footer from "./sections/footer";
import About from "./About";
import { useRef, useState, useEffect } from "react";
import helper from './helper';
import LocationsWidget from "./widgets/locations";

const { LOCATION_ID, determineLocationFromCoordinates } = helper;


function App() {
    const [showAbout, setShowAbout] = useState(false);
    const [selectedLocationId, setSelectedLocationId] = useState(LOCATION_ID.BLR_BSK)
    const [scrollToCommunity, setScrollToCommunity] = useState(false);
    const galleryRef = useRef(null);
    const serviceRef = useRef(null);
    // Flag to cancel the geolocation promise.
    let geoLocationCanceled = false;

    /* Updates localstorage and set the local state */
    const onLocationChange = (locationId = LOCATION_ID.BLR_BSK) => {
      if (!geoLocationCanceled) geoLocationCanceled = true;
      localStorage.setItem('locId', locationId);
      setSelectedLocationId(locationId);
    }

    const onLocSuccess = (position = {}) => {
      if (!geoLocationCanceled) {
        const { latitude, longitude } = position.coords;
        const locationId = determineLocationFromCoordinates(latitude, longitude);
        onLocationChange(locationId);
      }
    }

    const onLocError = () => {}
    const getLocationIdFromLocalStorage = () => {
      return localStorage.getItem('locId');
    }
    const scrollTo = (ref) => {
        ref?.current?.scrollIntoView({ behavior: "smooth", inline: "center" });
    };

    useEffect(() => {
      document.title = "Becky's Bow Wow";
      const locationId = getLocationIdFromLocalStorage()
      if (locationId) {
        onLocationChange(locationId);
      } else {
        if ("geolocation" in navigator) {
          /* geolocation is available */
          navigator?.geolocation?.getCurrentPosition(onLocSuccess, onLocError, { enableHighAccuracy: false, timeout: 10000 });
        }
      }
    }, [])

  return (
    <div className="becky-app">
        <Header setShow={setShowAbout} selectedAbout={showAbout} scrollToGallery={() => {
          setShowAbout(false);
          setTimeout(() => {
              scrollTo(galleryRef);
          }, 0)
        }
        } scrollToServices={() => {
          setShowAbout(false);
          setTimeout(() => {
              scrollTo(serviceRef);
          }, 0);
        }
            }
        setScrollToCommunity={setScrollToCommunity}
        selectedLocationId={selectedLocationId}
        setSelectedLocationId={(value) => {
            window.location.reload();
            return onLocationChange(value);
        }}
        />
        <div className="flex justify-center sm:hidden lg:hidden md:hidden">
            <LocationsWidget
                selectedLocationId={selectedLocationId}
                setSelectedLocationId={onLocationChange}
            />
        </div>
        { !showAbout && <>
            <br/>
            <Hero />
            <br/>
            <Introduction />
            <br/>
            <Banner />
            <br/>
            <div ref={serviceRef}>
                <Services locationId={selectedLocationId} />
            </div>
            <br/>
            <div ref={galleryRef}>
                <Gallery />
            </div>
            <br/>
            <Testimonials />
              <br/>
            <Banner />
            <br/>
        </> }
        { showAbout && <About scrollToCommunity={scrollToCommunity}/> }
        <Footer selectedLocationId={selectedLocationId} />
    </div>
  );
}

export default App;
