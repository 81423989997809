import React from 'react';
function Banner () {
    return(
        <div className="becky-app__banner_wrapper">
          <div className="becky-app__banner">
            <div className="becky-app__banner__offer">
                <div className="becky-text-heading-dark becky-main-title mb-8">
                    Still having trouble deciding?
                </div>
                <div className="becky-banner-text">
                    Feel free to book a visiting appointment and make your decision after experiencing our services firsthand.
                </div>
                <button
                    className="becky-button-outlined border-bbw-dark-brown text-bbw-dark-brown font-semibold pr-8 pl-8"
                    onClick={() => window.open('https://doggiedashboard.com/users/49a9896cd7c483d3b233/new_client', '_blank')}
                >Book now
                </button>
            </div>
            <div className="becky-app__banner__image">
                <img src="/assets/banner.png" alt="what we do"/>
            </div>
          </div>
        </div>
    )
}

export default Banner;