import React, {useEffect, useRef} from 'react';
import './About-style.css';
import Join from './join';
import Qualities from './qualities';
import Stories from './stories';
import Journey from './journey';
import Community from './community';

function Index ({scrollToCommunity}) {
    const communityRef = useRef(null);

     useEffect(() => {
         if(scrollToCommunity) communityRef?.current?.scrollIntoView({ behavior: "smooth", inline: "center" });
     }, [scrollToCommunity])
  return(
    <div className="becky-app__about">
      <Join />
      <br />
      <Qualities />
      <br />
      <Stories />
      <br />
      <Journey />
      <br />
        <div ref={communityRef}>
            <Community/>
        </div>
    </div>
  )
}

export default Index;