import React from 'react';
function Stories () {
  return(
    <div className="becky-app__stories">
      <div className="becky-app__stories-wallpaper">
        <span>we love <br /> sharing our story!</span>
      </div>
      <div className="becky-app__stories-body">
        <span>we love <br /> sharing our story!</span>
        <div className="becky-app__stories-content">
          <div className="becky-app__stories-content-description">
            We take pride in sharing our story of dedication, creating memorable moments for pets and their owners at Becky BowWow House.
          </div>
            <button className="becky-app__stories-content-action" onClick={
              () => window.open('https://instagram.com/beckysbowwowhouse_bsk?igshid=MTh4ZTlueW1jYmE5ZQ==', '_blank')
            }> Start Reading </button>
        </div>
      </div>

    </div>
  )
}

export default Stories;