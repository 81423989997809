import React from "react";
import helper from '../helper';

const { getInitials, generateBackground } = helper;

function LetteredAvatar ({ name }) {
  let initials = getInitials(name);
  let color = generateBackground(name);
  const customStyle =
    {
      display: "flex",
      height: "40px",
      width: "40px",
      borderRadius: "100px",
      color: "white",
      background: color,
      margin: "0 12px"
    }
  return (
    <div style={customStyle}>
      <span style={{margin: 'auto'}}> {initials} </span>
    </div>
  );
}

export default LetteredAvatar;