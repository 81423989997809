import React from 'react';
import helper from '../helper';

const { getAddress } = helper;

function Footer ({ selectedLocationId }) {
    return(
        <div className="becky-app__footer">
            <div className="becky-app__footer__left">
                {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
                <img width="255" src="/assets/logo.png" alt="image"/>
                <div className="becky-app__footer__address becky-text-x-small">
                    { getAddress(selectedLocationId) }
                </div>
            </div>
        </div>
    )
}

export default Footer;