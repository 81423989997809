import React from 'react';
function Introduction () {
    return(
        <div className="becky-app__intro">
          <div className="flex items-center flex-col text-center pt-5">
            <div className="becky-app__intro__heading becky-sub-title">
              Who are we?
            </div>
            <div className="becky-app__intro__subtext becky-section-description">
            Getting to know your pup's care provider is essential, as you are entrusting us with a furry member of your family. 
                    At Becky's Bow Wow House, you can have peace of mind knowing that your dog is in the best and most qualified hands in the business.
            </div>
          </div>
            <div className="becky-app__intro__image">
                <img src="/assets/whatwe.png" alt="what we do"/>
            </div>
            <div className="becky-app__intro__image-mobile">
                <img src="/assets/whatweMobile.png" alt="what we do"/>
            </div>
        </div>
    )
}

export default Introduction;